import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { WmkSeo } from "wmk-seo";

const NotFoundPage = () => (
  <Container>
    <Row>
      <WmkSeo.Meta
        title="404: Not found"
        siteTitle="UTRWD"
        slug="/404"
        baseUrl="https:/www.utrwd.com"
      />
      <Col style={{ minHeight: `50vh`, marginTop: `15vh` }}>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist.</p>
      </Col>
    </Row>
  </Container>
);

export default NotFoundPage;
